<template>
    <div class="m-5 surface-card p-4 shadow-2 border-round">
        <div class="text-center mb-5">
            <img src="/favicon.png" alt="Image" height="50" class="mb-3">
            <div class="text-900 text-3xl font-medium mb-3">Selamat Datang Pada Halaman Konfirmasi Pemberian Hadiah Survey {{this.survey}}</div>
             <p><b>Link WA Peserta: <a :href="'https://kirimwa.id/'+forms.no_wa">{{forms.no_wa}}</a></b></p>
            <span class="text-600 font-medium line-height-3">Silahkan isi field yang ada dibawah ini.</span>
            <br>
        </div>
        <hr>
        <div class="w-full md:w-10 mx-auto">
            <label for="no_wa" class="block text-900 text-xl font-medium mb-2">No. Whatsapp Peserta</label>
            <InputText v-model="forms.no_wa" required="true" type="text" class="w-full mb-2" placeholder="" style="padding:1rem;" disabled/>
            <small class="p-invalid" style="color: red" v-if="errorAdd.no_hp" >{{ errorAdd.no_hp[0] }}</small >

            <label for="kode" class="block text-900 text-xl font-medium mb-2">Kode Agent</label>
            <InputText v-model="forms.kode" required="true" type="text" class="w-full mb-2" placeholder="Silahkan Isi Kode Agent" style="padding:1rem;"/>
            <small class="p-invalid" style="color: red" v-if="errorAdd.kode_agent" >{{ errorAdd.kode_agent[0] }}</small >

            <Button label="Lanjutkan" :loading="loading" @click="cekData" class="w-full p-3 text-xl mt-3"></Button>
        </div>
        <div class="mt-5" v-if="is_checking == true">
            <Panel header="Hasil">
                <div v-if="this.is_konfirmasi == false">
                    <p class="text-5xl">🎉</p>
                    <p>{{this.msg}}</p>
                    <div class="mt-2 text-center">
                        <Button :loading="loadingKonfirmasi" label="Konfirmasi" icon="pi pi-save" class="p-button-primary" @click="saveNew"/>
                    </div>
                </div>
                <div v-else>
                    <p class="text-5xl">🎉</p>
                    <p>{{this.msg}}</p>
                </div>
            </Panel>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: [
		"survey",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingKonfirmasi: false,

            // addNew
            forms: {
                no_wa: null,
                kode: null,
            },

            msg: false,
            is_checking: false,
            is_konfirmasi: false,
            data: null,
        }
    },
    created() {
        this.forms.no_wa = this.$route.params.id;
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        // Check
        cekData() {
            this.loading = true;
            this.is_checking = false;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/survey/get-konfirmasi',
                data: {
                    "status": 'survey '+ this.survey,
                    "no_hp": this.forms.no_wa,
                    "kode_agent": this.forms.kode,
                },
            })
            .then((res) => {
                this.loading = false;
                this.data = res.data;

                if (res) {
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    
                   this.is_checking = true;
                   this.msg = this.data.data.message;

                   if(this.data.message == "Hadiah sudah diterima" || this.data.message == "Tidak memiliki hadiah"){
                        this.is_konfirmasi = true;
                   }else{
                        this.is_konfirmasi = false;
                   }
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Action', life: 3000,});
            });
        },
        saveNew() {
            this.loadingKonfirmasi = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/survey/konfirmasi-hadiah',
                data: {
                    "status": 'survey '+ this.survey,
                    "no_hp": this.forms.no_wa,
                    "kode_agent": this.forms.kode,
                },
            })
            .then((res) => {
                this.loadingKonfirmasi = false;

                if (res) {
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    
                    this.cekData();

                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingKonfirmasi = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Action', life: 3000,});
            });
        },
    },
}
</script>